(function busquedaAgenda(vg) {
  const polyglot = new window.Polyglot();
  polyglot.extend({
    idioma: {
      es: {lang: 'es-ES', texto: 'Castellano'},
      eu: {lang: 'eu-EU', texto: 'Euskera'},
      en: {lang: 'en-US', texto: 'English'},
      fr: {lang: 'fr-FR', texto: 'Français'},
    },
    idiomaCelebracion: {
      es: {es: 'Castellano', eu: 'Euskera', en: 'Inglés', fr: 'Francés', bl: 'Bilingüe', it: 'Italiano', de: 'Alemán'},
      eu: {es: 'Gaztelania', eu: 'Euskara', en: 'Ingelesa', fr: 'Frantsesa', bl: 'Elebiduna', it: 'Italiarra', de: 'Alemana'},
      en: {es: 'Spanish', eu: 'Basque', en: 'English', fr: 'French', bl: 'Bilingual', it: 'Italian', de: 'German'},
      fr: {es: 'Espagnol', eu: 'Basque', en: 'Anglais', fr: 'Français', bl: 'Bilingue', it: 'Italien', de: 'Allemand'},
    },
    texto: {
      titulo: {
        agenda: {
          es: 'Búsqueda de eventos',
          eu: 'Jarduerak bilatu',
          en: 'Events search',
          fr: `Eecherche d'événements`,
        },
        /* vistaDias: {
          es: 'Vista por días',
          eu: '(EU) Vista por días',
          en: '(EN) Vista por días',
          fr: '(FR) Vista por días',
        },
        vistaListado: {
          es: 'Vista listado',
          eu: '(EU) Vista listado',
          en: '(EN) Vista listado',
          fr: '(FR) Vista listado',
        },*/
        cancelado: {
          es: '(Suspendido)',
          eu: '(Bertan behera utzita)',
          en: '(Cancelled)',
          fr: `(Annulé)`,
        },
        botonBuscar: {
          es: 'Buscar',
          eu: 'Bilaketa',
          en: 'Search',
          fr: 'Rechercher',
        },
        inputTexto: {
          es: 'Texto',
          eu: 'Testu',
          en: 'Text',
          fr: 'Texte',
        },
        inputFechaDesdeHasta: {
          es: 'Fecha desde - hasta',
          eu: 'Noiztik - Noiz arte',
          en: 'Date from - to',
          fr: 'Date de - à',
        },
        inputQuitarFiltros: {
          es: 'Quitar filtros',
          eu: 'Iragazkiak kendu',
          en: 'Remove filters',
          fr: 'Supprimer les filtres',
        },
        inputCerrarMenuFiltros: {
          es: 'Cerrar menu filtros',
          eu: 'Itxi iragazki menua',
          en: 'Close filter menu',
          fr: 'Fermer le menu des filtres',
        },
        inputAplicarFiltros: {
          es: 'Aplicar filtros',
          eu: 'Iragazkiak aplikatu',
          en: 'Apply filters',
          fr: 'Appliquer les filtres',
        },
        enlaceRSS: {
          es: 'Descargar RSS',
          eu: 'Deskargatu RSS',
          en: 'Download RSS',
          fr: 'Télécharger RSS',
        },
        enlaceDescargarCalendario: {
          es: 'Descargar iCalendar',
          eu: 'iCalendar deskargatu',
          en: 'Download RSS',
          fr: 'Télécharger le calendrier',
        },
        /* resutadosVistaPorDia: {
          es: 'Eventos por día',
          eu: '(EU) Eventos por día',
          en: '(EN) Eventos por día',
          fr: '(FR) Eventos por día',
        },*/
        resutadosVistaListado: {
          es: 'Listado de eventos',
          eu: 'Ekitaldien zerrenda',
          en: 'List of events',
          fr: 'Liste des événements',
        },
        mobileFiltrar: {
          es: 'Filtrar por',
          eu: 'Iragazi',
          en: 'Filter by',
          fr: 'Filtrer par',
        },
        mobileBotonFiltrar: {
          es: 'Filtrar',
          eu: 'Iragazi',
          en: 'Filter',
          fr: 'Filtrer',
        },
        mobileVerResultados: {
          es: 'Ver resultados',
          eu: 'Ikusi emaitzak',
          en: 'See the results',
          fr: 'Voir les résultats',
        },
        verMasResultados: {
          es: 'Ver más resultados',
          eu: 'Ikusi emaitza gehiago',
          en: 'See more results',
          fr: 'Voir plus de résultats',
        },
        noHayDatos: {
          es: 'No hay resultados para tu consulta. Prueba con otros criterios de búsqueda.',
          eu: 'Ez dago emaitzarik zure bilaketarako. Saiatu beste bilaketa-irizpide batzuekin.',
          en: 'There are no results for your query. Try other search criteria.',
          fr: `Il n'y a aucun résultat pour votre requête. Essayez d'autres critères de recherche.`,
        },
        botonSegmentadoExposiciones: {
          es: 'Exposiciones',
          eu: 'Erakusketak',
          en: 'Art exhibitions',
          fr: `Des expositions`,
        },
        botonSegmentadoActividades: {
          es: 'Actividades',
          eu: 'Jarduerak',
          en: 'Activities',
          fr: `Activités`,
        },
      },
      placeholder: {
        fechaDesdeHasta: {
          es: 'dd/MM/yyyy - dd/MM/yyyy',
          eu: 'yyyy/MM/dd - yyyy/MM/dd',
          en: 'MM/dd/yyyy - MM/dd/yyyy',
          fr: 'dd/MM/yyyy - dd/MM/yyyy',
        },
        buscar: {
          es: 'escribe el texto a buscar',
          eu: 'bilaketa egiteko testua',
          en: 'search for this text',
          fr: 'écrivez le texte à rechercher',
        },
      },
      separador: {
        es: 'y',
        eu: 'eta',
        en: 'and',
        fr: 'et',
      },
      formatoFecha: {
        es: '%{dayWeek} %{day} %{month}%{year}',
        eu: '%{month} %{day} %{dayWeek}%{year}',
        en: '%{dayWeek} %{day} %{month}%{year}',
        fr: '%{dayWeek} %{day} %{month}%{year}',
      },
      error: {
        cargarFecth: {
          es: 'Hubo un problema al reaizar la búsqueda.<br />Vuelva a intentarlo dentro de unos minutos o contacte con el administrador. Disculpe las molestias.',
          eu: 'Arazo bat izan da bilaketa egitean.<br />Saiatu berriro minutu batzuk barru edo administratzailearekin kontaktatu. Barka enbarazua.',
          en: 'There was a problem performing the search.<br />Try again in a few minutes or contact the administrator. We apologise for any inconvenience.',
          fr: 'Un problème est survenu lors de la recherche.<br />Merci de réessayer dans quelques minutes ou veuillez contacter le Webmaster. Merci de nous excuser.',
        },
      },
      repeat: {
        es: 'Varias fechas',
        eu: 'Zenbait data',
        en: 'Various dates',
        fr: 'Différentes dates',
      },
    },
    formato: {
      fecha: {
        es: 'DD/MM/YYYY',
        eu: 'YYYY/MM/DD',
        en: 'MM/DD/YYYY',
        fr: 'DD/MM/YYYY',
      },
    },
  });

  const idioma = vg.utils.lang();

  const visibilidad = {
    INTRANET: 'intranet',
    WEB: 'web',
    SEDE: 'sede',
  };

  const tipo = {
    COMPLETO: 'all',
    RESULTADOS: 'results',
    RESULTADOS_SIN_DESCARGA: 'results_sin_rss',
  };

  const appWeb = '/wb021/was/CalendarioServlet?';
  const appSede = '/j30-01s/CalendarioServlet?';
  const appIntranet = '/ib025/was/CalendarioServlet?';

  const appRssWeb = '/we001/was/rssAction.do?';
  const appRssSede = '/j30-01s/rssAction.do?';
  const appRssIntranet = '/ib025/was/rssAction.do?';

  const paramsBuscar = `accion=buscar&idioma=${idioma}`;
  const paramsBuscarMasDatos = `accion=listadoPorPagina&idioma=${idioma}`;
  const paramsDownloadIcalendar = `accion=icalendar&idioma=${idioma}`;
  const paramsRss = `accion=calendario&idioma=${idioma}`;

  let urlBuscar = appWeb + paramsBuscar;
  let urlBuscarMasDatos = appWeb + paramsBuscarMasDatos;
  let urlDownloadIcalendar = appWeb + paramsDownloadIcalendar;
  let urlRss = appRssWeb + paramsRss;

  /**
   * Gestiona todo lo relacionado con la búsqueda de eventos.
   * Se puede mostrar sólo el listado de eventos o el listado + buscador
   * @param {Object} options objeto
   */
  function busquedaFactory(options) {
    var node = null;
    var eventScroll = null;
    var fechaDesdeHasta = null;
    var jsonAgenda = null;
    var elementoTermino = null;
    var parametrosBusquedaAnterior = '';
    var fechaHasta = new Date();
    fechaHasta.setDate(fechaHasta.getDate() + 31);

    const defaultConfig = {
      identificador: new Date().getTime(),
      idSection: 'sectBusqueda',
      type: tipo.COMPLETO,
      titulo: polyglot.t(`texto.titulo.agenda.${idioma}`),
      // tituloVistaDias: polyglot.t(`texto.titulo.vistaDias.${idioma}`),
      // tituloVistaListado: polyglot.t(`texto.titulo.vistaListado.${idioma}`),
      tituloBotonBuscar: polyglot.t(`texto.titulo.botonBuscar.${idioma}`),
      tituloInputTexto: polyglot.t(`texto.titulo.inputTexto.${idioma}`),
      tituloInputFechaDesdeHasta: polyglot.t(`texto.titulo.inputFechaDesdeHasta.${idioma}`),
      tituloInputQuitarFiltros: polyglot.t(`texto.titulo.inputQuitarFiltros.${idioma}`),
      tituloInputCerrarMenuFiltros: polyglot.t(`texto.titulo.inputCerrarMenuFiltros.${idioma}`),
      tituloInputAplicarFiltros: polyglot.t(`texto.titulo.inputAplicarFiltros.${idioma}`),
      tituloEnlaceRSS: polyglot.t(`texto.titulo.enlaceRSS.${idioma}`),
      tituloEnlaceDescargarCalendario: polyglot.t(`texto.titulo.enlaceDescargarCalendario.${idioma}`),
      /* tituloResutadosVistaPorDia: polyglot.t(`texto.titulo.resutadosVistaPorDia.${idioma}`),*/
      tituloResutadosVistaListado: polyglot.t(`texto.titulo.resutadosVistaListado.${idioma}`),
      tituloMobileFiltrar: polyglot.t(`texto.titulo.mobileFiltrar.${idioma}`),
      tituloMobileBotonFiltrar: polyglot.t(`texto.titulo.mobileFiltrar.${idioma}`),
      tituloMobileVerResultados: polyglot.t(`texto.titulo.mobileVerResultados.${idioma}`),
      tituloVerMasResultados: polyglot.t(`texto.titulo.verMasResultados.${idioma}`),
      tituloCancelado: polyglot.t(`texto.titulo.cancelado.${idioma}`),
      botonSegmentadoExposiciones: polyglot.t(`texto.titulo.botonSegmentadoExposiciones.${idioma}`),
      botonSegmentadoActividades: polyglot.t(`texto.titulo.botonSegmentadoActividades.${idioma}`),
      separador: polyglot.t(`texto.separador.${idioma}`),
      errorCargaFecth: polyglot.t(`texto.error.cargarFecth.${idioma}`),
      placeHolderFechaDesdeHasta: polyglot.t(`texto.placeholder.fechaDesdeHasta.${idioma}`),
      placeHolderBuscar: polyglot.t(`texto.placeholder.buscar.${idioma}`),
      fechaDesde: new Date(),
      fechaHasta: fechaHasta,
      filtrosSeleccionados: '',
      idJsonAgenda: 'jsonCalendarioCompleto',
      claveArea: '',
      claveTema: '',
      idCalendario: '',
      paramIdCalendario: 'calendariosID',
      visibilidad: visibilidad.WEB,
      terminoBusqueda: '',
      permitirRSS: false,
      permitirDescargar: false,
      permitirCargaScroll: false,
      mostrarHeader: true,
      msgSinDatos: polyglot.t(`texto.titulo.noHayDatos.${idioma}`),
      esCuadroMando: false,
      viewExposiciones: false,
    };

    var config = Object.assign({}, defaultConfig, options);
    var capaError = null;
    var capaResultados = null;
    var capaFiltros = null;
    var capaFiltrosSeleccionados = null;
    var capaDescargas = null;
    var capaViews = null;

    var botonBuscar = null;
    var capaBotonFiltrar = null;
    var titResultadosAgenda = null;

    var _filtros = null;
    var posicionResultadosBusqueda = 0;

    var returnedObject = {};


    /**
     * Sustituye al querySelector para obligar que la busqueda
     * la haga siempre sobre la capa del componente.
     * @param {*} search
     * @return {Element}
     */
    const querySelector = (search) => document.querySelector(`#${config.idSection} ${search}`);

    /**
     * Resetea a 0 el inicio de busqueda de elementos. Esto es
     * porque busca de 'n' a 'n' elementos según hace scroll
     * que haga el usuario.
     */
    const resetDatosBusqueda = () => {
      posicionResultadosBusqueda = 0;
    };

    /**
     * Establece el estado del historial del navegador.
     * @param {Object} stateObj
     */
    const setStateHistory = (stateObj) => {
      const currentState = history.state;

      if (currentState) {
        if (currentState.params === stateObj.params) {
          history.replaceState(stateObj, '', stateObj.url);
        } else {
          history.pushState(stateObj, '', stateObj.url);
        }
      } else {
        history.pushState(stateObj, '', stateObj.url);
      }
    };

    /**
     * Objeto para realizar un download creando un form y un iframe
     * Si no existe un div con id="downloadAgenda" lo crea al final del body
     * @param {string} url
     * @param {string} method
     * @param {Array} params
     */
    /* function download(url, method, params) {
      let html = '';
      let capaDownload = document.querySelector('#downloadAgenda');

      if (!capaDownload) {
        capaDownload = document.createElement('div');
        capaDownload.setAttribute('id', 'downloadAgenda');
        capaDownload.classList.add('invisible');

        document.body.appendChild(capaDownload);
      }
      html = `<form id="downloadAgenda_${config.idSection}" method="${method}"
              action="${url}" target="downloadFrame_${config.idSection}">"`;

      // Introduce los parámetros
      for (var key in params) {
        html += `<input type="hidden" id="down_${key}" name="${key}">`;
      }
      html += '</form>';
      html += '<iframe name="downloadFrame_' + config.idSection + '"></iframe>';

      capaDownload.innerHTML = html;

      // Se rellenan así los input para escapar correctamente
      // los caracteres especiales
      for (var key in params) {
        document.querySelector(`#down_${key}`).value = params[key];
      }

      document.querySelector('#downloadForm_' + config.idSection).submit();
    } */

    /**
    * Método genérico de busqueda de eventos.
    * @param {*} stateObj
    * @return {boolean} todoOk
    */
    function buscar(stateObj) {
      //  async function buscar (stateObj) {
      let urlParams = '';

      if (stateObj.nuevosFiltros) {
        borrarCapaFiltros();
      }

      mostrarSpinner(capaResultados.id);
      quitarError();

      if (stateObj.params) {
        urlParams = stateObj.params;
      } else {
        urlParams += (!stateObj.nuevosFiltros
          ?parametrosBusquedaAnterior + generarParametrosFiltros()
          :generarParametrosBusqueda());
      }

      return fetch(urlBuscar + urlParams,
        {
          method: 'POST',
          credentials: 'same-origin',
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          jsonAgenda = json;
          if (jsonAgenda) {
            dibujarResultados();

            actualizarFormBusquedaYFiltros(stateObj);

            dibujarFiltros(stateObj.nuevosFiltros);

            if (jsonAgenda.noHayDatosDiaSeleccionado) {
              marcarError('No hay eventos para el día ' + jsonAgenda.diaSeleccionado + '. A continuación mostramos dias previos donde hay eventos.');
            }
          }
          if (!stateObj.filtros) {
            const filtros = obtenerFiltros();
            const stateObjNew = {
              url: getUrlWithParams(urlParams),
              params: urlParams,
              accion: 'buscar',
              filtros: filtros,
              nuevosFiltros: !filtros || filtros === '',
              titulo: (elementoTermino && elementoTermino.value ? elementoTermino.value : ''),
              fechaInicio: fechaDesdeHasta.getStartDate().getTime(),
              fechaFin: fechaDesdeHasta.getEndDate().getTime(),
            };

            setStateHistory(stateObjNew);
          }
          return true;
        })
        .catch(function (error) {
          quitarSpinner(capaResultados.id);
          marcarError(config.errorCargaFecth);
          console.log('Hubo un problema con la petición Fetch:' + error.message);
          return false;
        });
    }

    /*
    function cerrarFiltros() {
      // document.querySelector(".sidebar-heading").click();
    } */

    /**
     * Elimina todos los elementos DOM del array
     * @param {Array} array
     */
    const eliminarElementosArray = (array) => {
      array.forEach((elem) => {
        elem.remove();
      });
    };

    /**
     * Elimina tanto la capa donde aparecen los filtros a seleccionar
     * como la capa de filtros ya seleccionados.
     */
    const borrarCapaFiltros = () => {
      if (capaFiltros) {
        eliminarElementosArray(Array.from(capaBotonFiltrar.children));
        eliminarElementosArray(Array.from(capaFiltros.children));
      }
      if (capaFiltrosSeleccionados) {
        eliminarElementosArray(Array.from(capaFiltrosSeleccionados.children));
      }
      if (capaViews) {
        eliminarElementosArray(Array.from(capaViews.children));
      }
    };

    const getUrlDescargarICalendar = () => {
      if (parametrosBusquedaAnterior) {
        return urlDownloadIcalendar + parametrosBusquedaAnterior;
      }
      return `${urlDownloadIcalendar}&fd=${config.fechaDesde}&fh=${config.fechaHasta}&calendariosID=${config.idCalendario}&claveArea=${config.claveArea}&claveTema=${config.claveTema}`;
    };

    const getUrlRss = () => `${urlRss}&idCalendario=${config.idCalendario}&claveArea=${config.claveArea}&claveTema=${config.claveTema}`;
    const htmlDescargarRSS = () => `<a class="descargas__rss" href="${getUrlRss()}">${config.tituloEnlaceRSS} <span class="icon-social-rss-small"></span></a>`;
    const htmlDescargarICalendar = () => `<a class="descargas__icalendar" href="${getUrlDescargarICalendar()}">${config.tituloEnlaceDescargarCalendario}</a>`;
    const htmlCapaDescargas = () => `<div id="descargas_${config.identificador}">${config.permitirRSS? htmlDescargarRSS(): ''}${config.permitirDescargar? htmlDescargarICalendar(): ''}</div>`;

    /**
     * Dibuja la capa donde irá sólo el listado de eventos, el mensaje de error
     * y dos enlaces (rss y descargar listado).
     */
    function dibujarSeccionSoloResultados() {
      var html =`${config.type === tipo.RESULTADOS && (config.permitirRSS || config.permitirDescargar)? htmlCapaDescargas(): ''}
          <section class="main__results" id="results-search_${config.identificador}" aria-live="polite">
            <div>
              <p id="idError_${config.identificador}" role="alert" class="msg msg--error"></p>
              <div id="resultadosAgenda_${config.identificador}" class="div__results"></div>
            </div>
          </section>`;

      node = document.getElementById(config.idSection);
      node.innerHTML = html;
    }

    /**
     * Dibuja la capa donde se mostrará el buscador, los filtros,
     * listado de eventos, el mensaje de error
     * y dos enlaces (rss y descargar listado).
     */
    function dibujarSeccionBuscador() {
      var html = '';
      var htmlContent =
        `<form class="searchbox" role="search">
              <div class="searchbox__search">
                <div class="field field--text">
                  <label class="field__label oculto" for="terminoAgenda_${config.identificador}">${config.tituloInputTexto}</label>
                  <input class="field__input" type="text" maxlength="500" onfocus="this.select();" id="terminoAgenda_${config.identificador}" placeholder="${config.placeHolderBuscar}" value="">
                </div>
                <div class="field field--dateRange">
                  <label class="field__label oculto" for="fechaDesdeHasta_${config.identificador}">${config.tituloInputFechaDesdeHasta}</label>
                  <input class="field__input" id="fechaDesdeHasta_${config.identificador}" placeholder="${config.placeHolderFechaDesdeHasta}">
                </div>
                <button type="submit"id="btnBuscar_${config.identificador}" class="btn" aria-controls="results-search_${config.identificador}">${config.tituloBotonBuscar}</button>
              </div>
          </form>
          <div class="searchbox__filters">
            <div id="filtrarMobile_${config.identificador}" class="visible-xs"></div>
            <div id="filtrosAgenda_${config.identificador}" class="hidden-xs"></div>
            <div id="filtrosSeleccionados_${config.identificador}" class="filters-selected" aria-live="assertive" aria-atomic="true" aria-relevant="additions"></div>
          </div>
          <div class="searchbox__views">
            <div id="viewsAgenda_${config.identificador}"></div>
          </div>
          <section class="main__results" id="results-search_${config.identificador}" aria-live="polite">
            <header class="main__header main__grid-box main__header--inline oculto">
              <h3 id="titResultadosAgenda_${config.identificador}">${config.tituloResutadosVistaListado}</h3>
            </header>
            <div>
              <p id="idError_${config.identificador}" role="alert" class="msg msg--error"></p>
              <div id="resultadosAgenda_${config.identificador}" class="div__results"></div>
              ${htmlCapaDescargas()}
            </div>
          </section>`;

      if (config.mostrarHeader) {
        html =
        `<section class="main row">
            <header class="main__header main__grid-box">
              <h2><span>${config.titulo}</span></h2>
            </header>
            ${htmlContent}
        </section>`;
      } else {
        html = `<div class="main row result-elements">${htmlContent}</div>`;
      }
      node = document.getElementById(config.idSection);
      node.innerHTML = html;
    }

    /**
     * Dibuja el resultado de busqueda, asigna la posicion del ultimo
     * elemento encontrado y si no hay más resultados bloquea el evento
     * de scroll para que no busque más resultados.
     */
    const dibujarResultados = () => {
      var html = `${dibujarResultadosListado(jsonAgenda)}`;

      if (config.type === tipo.COMPLETO) {
        titResultadosAgenda.innerHTML = config.tituloResutadosVistaListado;
      }
      capaResultados.innerHTML = html;

      posicionResultadosBusqueda = jsonAgenda.posicionResultado;

      const times = document.querySelectorAll('.event-list__section');
      generarIntersectionObserver(times);

      eventScroll.bloquearScroll(!config.permitirCargaScroll || (config.permitirCargaScroll && !jsonAgenda.hayMasDatos));

      if (config.type === tipo.COMPLETO) {
        if (!config.permitirCargaScroll && jsonAgenda.hayMasDatos) {
          dibujarBotonMasResultados();
        }
      }
    };

    /**
     * Devuelve la fecha del evento formateada según el idioma
     * @param {string} idioma
     * @param {Object} evento
     * @param {boolean} mostrarYear
     * @return {string} fechaFormateada
     */
    const getFechaListado = (idioma, evento, mostrarYear) => polyglot.t(`texto.formatoFecha.${idioma}`, {
      day: evento.day,
      dayWeek: evento.dayWeek,
      month: evento.monthText,
      year: (mostrarYear? `: ${evento.year}`: ''),
    });

    /**
     * Devuelve la fecha en formato GMT yyyy-MM-dd
     * @param {Number} year
     * @param {Number} month
     * @param {Number} day
     * @return {string}
     */
    const getFechaFormatoGMT = (year, month, day) => new Date(Date.UTC(year, month, day)).toISOString().split('T')[0];

    /**
     * Método que dibuja un listado de eventos al hacer scroll.
     * @param {Array} resultados
     */
    function dibujarResultadoListadoScroll(resultados) {
      if (resultados) {
        var capaUltimaFecha = capaResultados.querySelector('li[data-fecha]:last-child');
        var antUltimoDiaDibujado = capaUltimaFecha.dataset.fecha;
        var antDia = '';
        var seccionDiaAbierta = false;
        var antYear = antUltimoDiaDibujado.substr(0, 4);
        var html = '';
        resultados.forEach(function (evento) {
          // empezamos un nuevo listado
          if (antDia != evento.fechaInicio) {
            if (seccionDiaAbierta) {
              html += '</ul></li>';
            }
            seccionDiaAbierta = true;
            antDia = evento.fechaInicio;

            html += `<li data-fecha="${evento.fechaInicio}" class="event-list__section">
                        <time class="event-view-list__section-title" datetime="${getFechaFormatoGMT(evento.year, evento.month, evento.day)}">${getFechaListado(idioma, evento, antYear != evento.year)}</time>
                        <ul class="card-collection">`;
            antYear = evento.year;
          }
          html += '<li class="event-list__item event-list__item--acto">';
          // acto o evento
          html += dibujarCapaEvento(evento);
          html += '</li>';
        });
        // Cerrar la utima seccion tras el ultimo acto/evento (fuera del for)
        if (seccionDiaAbierta) {
          html += '</ul></li>';
          capaUltimaFecha.insertAdjacentHTML('afterend', html);
        }

        let siguiente = capaUltimaFecha.nextElementSibling;
        const siguientesHermanos = [];
        while (siguiente) {
          siguientesHermanos.push(siguiente);
          siguiente = siguiente.nextElementSibling;
        }
        generarIntersectionObserver(siguientesHermanos);
      }
    }

    /**
     * Método que retorna el html de un evento
     * @param {Object} evento
     * @return {string} htmlEvento
     */
    function dibujarCapaEvento(evento) {
      var htmlEvento = '';
      var horario = '';

      if (evento.horarioApertura) {
        var jsonListadoHorario = JSON.parse(evento.horarioApertura);

        jsonListadoHorario.forEach(function (horarioApertura) {
          if (horario) {
            horario += ' ' + config.separador + ' ';
          }
          horario += horarioApertura.inicio + '-' + horarioApertura.fin;
        });
      } else {
        horario = evento.horaInicio;
        if (!evento.aproxFin) {
          horario = horario + '-' + evento.horaFin;
        }
      }

      // acto o evento
      htmlEvento += `<article class="card-event card">
                <a href="${evento.url}" class="card__block-link card-event__link ${(evento.isTipoActo? '--acto': '--evento')}">`;

      if (evento.picture) {
        htmlEvento += `<div class="card-event__image">${evento.picture}</div>`;
      } /* else {
        htmlEvento +=
        '<div class="card-event__image card-event__image--notfound"></div>';
      }*/

      htmlEvento += '<div class="card-event__content">';

      if (evento.categorias) {
        htmlEvento += '<div class="card-event__metas">';

        evento.categorias.forEach(function (categoria) {
          htmlEvento += `<span class="meta__etiqueta">${categoria}</span>`;
        });
        htmlEvento += '</div>';
      }

      htmlEvento += `<p class="card-event__title">${evento.titulo}${evento.isCancelado? ' ' + config.tituloCancelado: ''}</p>
                <ul class="card-event__info-list">
                  <li class="card-event__info card-event__info-time">
                    <time datetime="${evento.datetime}">${horario}</time>
                  </li>`;

      if (evento.localizacion !== '') {
        htmlEvento += `<li class="card-event__info card-event__info-location">
                    ${evento.localizacion}
                  </li>`;
      }

      if (evento.destinatario !== '') {
        htmlEvento += `<li class="card-event__info card-event__info-public">
                    ${evento.destinatario}
                  </li>`;
      }

      if (evento.idiomaCelebracion !== '') {
        const langText = polyglot.t(`idiomaCelebracion.${idioma}.${evento.idiomaCelebracion}`);
        htmlEvento += `<li class="card-event__info card-event__info-lang">
                    ${langText}
                  </li>`;
      }

      if (evento.numeroRepeticiones > 1) {
        const repeatText = polyglot.t(`texto.repeat.${idioma}`);
        htmlEvento += `<li class="card-event__info card-event__info-repeat">
                    ${repeatText}
                  </li>`;
      }

      htmlEvento += '</ul></div></a></article>';
      return htmlEvento;
    }

    /**
     * Método que devuelve el html con los resultados de la búsqueda
     * @param {Object} json
     * @return {string} html
     */
    function dibujarResultadosListado(json) {
      var html = '';

      if (json && json.resultados) {
        var antDia = '';
        var seccionDiaAbierta = false;
        var antYear = json.resultados[0].year;

        html += '<ul id="event-list" class="event-list" aria-live="assertive" aria-atomic="true" aria-relevant="additions">';

        json.resultados.forEach(function (evento) {
          if (antDia != evento.fechaInicio) {
            if (seccionDiaAbierta) {
              html += '</ul></li>';
            }
            seccionDiaAbierta = true;
            antDia = evento.fechaInicio;

            html += `<li data-fecha="${evento.fechaInicio}" class="event-list__section">
                      <time class="event-view-list__section-title" datetime="${getFechaFormatoGMT(evento.year, evento.month, evento.day)}">${getFechaListado(idioma, evento, antYear != evento.year)}</time>
                      <ul class="card-collection">`;
            antYear = evento.year;
          }

          html += '<li class="event-list__item event-list__item--acto">';
          // acto o evento
          html += dibujarCapaEvento(evento);
          html += '</li>';
        });

        // Cerrar la utima seccion tras el ultimo acto/evento (fuera del for)
        if (seccionDiaAbierta) {
          html += '</ul></li>';
        }

        // Cerrar event-list
        html += '</ul>';
        if (capaDescargas) {
          mostrar(capaDescargas.id);
        }
      } else {
        html += `<p>${config.msgSinDatos}</p>`;
        if (capaDescargas) {
          ocultar(capaDescargas.id);
        }
      }
      return html;
    }

    /*
    function dibujarResultadosDia(json) {
      var html = '';

      if (json && json.resultadosDia) {
        var antHora = '';
        var seccionHoraAbierta = false;

        html += '<ul class="event-view-day">';
        json.resultadosDia.forEach(function (evento) {
          if (antHora != evento.horaInicio) {
            if (seccionHoraAbierta) {
              html += '</ul></li>';
            }
            seccionHoraAbierta = true;
            antHora = evento.horaInicio;

            html += '<li class="event-view-day__section">';
            html += '<span class="event-view-day__section-title">';
            html += '<svg class="list-svg__icon" viewBox="0 0 48 48">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" href="#svg-horario"></use></svg>';
            html += evento.horaInicio + '</span>';
            html += '<ul class="event-view-day__section-items card-collection
            card__event-collection has-2-cols@sm has-4-cols@md">';
          }

          // acto o evento
          html +=
                    '<li class="card-collection__item event-view-day__item ' +
                    (evento.isTipoActo
                      ? 'event-view-day__item--acto'
                      : 'event-view-day__item--evento') +
                    '">' +
                    '<article class="event-card card">' +
                    '<a href="' + evento.url +
                    '" class="event-view-day__item-link card__block-link">';

          if (evento.picture) {
            html +=
                        '<div class="card__image">' + evento.picture + '</div>';
          } else {
            html += '<div class="card__image not-found"></div>';
          }

          html +=
                    '<div class="card__content"><p class="card__title">' +
                     evento.titulo +'</p></div></a>' +
                    '</article>' +
                    '</li>';
        });

        // Cerrar la utima seccion tras el ultimo acto/evento (fuera del for)
        if (seccionHoraAbierta) {
          html += '</ul></li>';
        }

        // Cerrar event-list
        html += '</ul>';
      } else {
        html += '<p>No hay resultados para los filtros seleccionados</p>';
      }
      return html;
    } */

    /**
     * Genera y retorna los parametros de búsqueda.
     * @return {string} parametrosURL
     */
    const generarParametrosBusqueda = () => {
      parametrosBusquedaAnterior = `&claveArea=${config.claveArea}&claveTema=${config.claveTema}&${config.paramIdCalendario}=${config.idCalendario}&t=${(elementoTermino && elementoTermino.value ? elementoTermino.value : '')}&fd=${(fechaDesdeHasta? fechaDesdeHasta.getStartDate().getTime() : '')}&fh=${(fechaDesdeHasta? fechaDesdeHasta.getEndDate().getTime() : '')}&deCM=${config.esCuadroMando}}}&moEx=${config.viewExposiciones}`;
      return parametrosBusquedaAnterior + generarParametrosFiltros();
    };

    /**
     * Genera y retorna el parametro de filtros seleccionados para la búsqueda.
     * @return {string} filtrosSeleccionados
     */
    const generarParametrosFiltros = () => `&f=${obtenerFiltros()}`;

    /**
     * Retorna los filtros seleccionados
     * @return {string}
     */
    const obtenerFiltros = () => {
      var jsonFiltrosSeleccionados = obtenerFiltrosSeleccionados();
      return (Object.keys(jsonFiltrosSeleccionados).length === 0? '' : JSON.stringify(jsonFiltrosSeleccionados));
    };

    /**
     * Función que se hace cargo de mostrar el elemento adecuado para el IntersectionObserver
     * @param {*} entries
     * @param {*} observer
     */
    const handleIntersection = (entries, observer) => {
      entries.map((entry) => {
        if (entry.isIntersecting) {
          entry.target.setAttribute('data-stuck', true);
        } else {
          if (entry.target.hasAttribute('data-stuck')) {
            entry.target.removeAttribute('data-stuck');
            // observer.unobserve(entry.target);
          }
        }
      });
    };

    /**
     * Observador para poder aplicar un estilo a los días al hacer scroll.
     * @param {*} entries Las entradas a observar.
     */
    const generarIntersectionObserver = (entries) => {
      const observer = new IntersectionObserver(handleIntersection, {threshold: 0.05, rootMargin: '-5% 0px 0px 0px'});
      entries.forEach((entry) => observer.observe(entry));
    };

    /**
     * Establece los eventos de búsqueda
     */
    const initEventos = () => {
      eventScroll = window.vg.eventScroll.init({
        idCapa: capaResultados.id,
        lockScroll: true,
        callback: cargarMasListado,
      });

      if (config.type === tipo.COMPLETO) {
        botonBuscar.addEventListener('click', function (e) {
          e.preventDefault();
          resetDatosBusqueda();

          eventScroll.reset();
          eventScroll.bloquearScroll(!config.permitirCargaScroll );

          buscar({nuevosFiltros: true});
        });

        elementoTermino.addEventListener('keyup', function (event) {
        // Number 13 is the "Enter" key on the keyboard
          if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();

            resetDatosBusqueda();

            eventScroll.reset();
            eventScroll.bloquearScroll(!config.permitirCargaScroll );

            buscar({nuevosFiltros: true});
          }
        });
      } else {
        eventScroll.bloquearScroll(!config.permitirCargaScroll);
      }
    };


    /**
     * Inicializa los campos de búsqueda
     */
    const initCamposBuscador = () => {
      if (config.type === tipo.COMPLETO) {
        // inicializar campo de fecha
        initFechaDesdeHasta();
        initTerminoBusqueda();

        capaBotonFiltrar = querySelector(`#filtrarMobile_${config.identificador}`);
        capaFiltros = querySelector(`#filtrosAgenda_${config.identificador}`);
        capaFiltrosSeleccionados = querySelector(`#filtrosSeleccionados_${config.identificador}`);
        capaViews = querySelector(`#viewsAgenda_${config.identificador}`);

        botonBuscar = querySelector(`#btnBuscar_${config.identificador}`);
        titResultadosAgenda = querySelector(`#titResultadosAgenda_${config.identificador}`);
      }

      capaError = querySelector(`#idError_${config.identificador}`);
      capaResultados = querySelector(`#resultadosAgenda_${config.identificador}`);

      if (config.type !== tipo.RESULTADOS_SIN_DESCARGA) {
        capaDescargas = querySelector(`#descargas_${config.identificador}`);
      }
    };

    /**
     * Inicializa el campo de búsqueda
     */
    const initTerminoBusqueda = () => {
      elementoTermino = querySelector(`#terminoAgenda_${config.identificador}`);
      elementoTermino.value = config.terminoBusqueda;
    };

    /**
     * Inicializa las fechas de búsqueda
    */
    const initFechaDesdeHasta = () => {
      const isMobileDevice = vg.utils.isMobileDevice();
      const configFechaDefault = {
        element: querySelector(`#fechaDesdeHasta_${config.identificador}`),
        dropdowns: {'minYear': 1980, 'maxYear': ((new Date()).getFullYear() + 1), 'months': true, 'years': true},
        firstDay: 1,
        numberOfMonths: 2,
        format: polyglot.t(`formato.fecha.${idioma}`),
        // lang: lang[idioma],
        lang: polyglot.t(`idioma.${idioma}.lang`),
        singleMode: false,
        tooltipText: {
          one: 'día',
          other: 'días',
        },
        tooltipNumber: function (totalDays) {
          return totalDays;
        },
        startDate: config.fechaDesde,
        endDate: config.fechaHasta,
        // plugins: ['ranges'],
        setup: (picker) => {
          if (!isMobileDevice) {
            let eventoBotonMes = '';
            picker.on('change:month', (date, calendarIdx) => {
              if (date && eventoBotonMes != '') {
                if (eventoBotonMes === 'next') {
                  date.setMonth(date.getMonth() - 1);
                } else {
                  date.setMonth(date.getMonth() + 1);
                }
                picker.gotoDate(date);
              }
            });

            picker.on('before:click', (target) => {
              if (target.classList.contains('button-previous-month')) {
                eventoBotonMes = 'previous';
              } else if (target.classList.contains('button-next-month')) {
                eventoBotonMes = 'next';
              } else {
                eventoBotonMes = '';
              }
            });
          }
        },
      };


      const options = {
        numberOfMonths: isMobileDevice? 1: 2,
        numberOfColumns: isMobileDevice? 1: 2,
        /* ranges: {
          position: isMobileDevice? 'top': 'left',
        },*/
        // plugins: isMobileDevice? ['ranges', 'mobilefriendly']: ['ranges'],
        plugins: isMobileDevice? ['mobilefriendly']: [],
      };

      const configFechaDevice = Object.assign({}, configFechaDefault, options);
      const datepickerDesde = new window.Litepicker(configFechaDevice);

      querySelector(`#fechaDesdeHasta_${config.identificador}`).datepicker = datepickerDesde;
      fechaDesdeHasta = datepickerDesde;
    };

    /**
     * Inicializa las urls de búsquqeda y de descargar el calendario
     * según la visibilidad
     */
    const initVisibilidad = () => {
      if (config.visibilidad === visibilidad.INTRANET) {
        urlBuscar = appIntranet + paramsBuscar;
        urlBuscarMasDatos = appIntranet + paramsBuscarMasDatos;
        urlDownloadIcalendar = appIntranet + paramsDownloadIcalendar;
        urlRss = appRssIntranet + paramsRss;
      } else if (config.visibilidad === visibilidad.SEDE) {
        urlBuscar = appSede + paramsBuscar;
        urlBuscarMasDatos = appSede + paramsBuscarMasDatos;
        urlDownloadIcalendar = appSede + paramsDownloadIcalendar;
        urlRss = appRssSede + paramsRss;
      }
    };

    /**
     * Inicializa el json que devuelve la busqueda
     */
    const initJsonResultados = () => {
      var elementoJsonAgenda = querySelector(`#${config.idJsonAgenda}`);
      if (elementoJsonAgenda && elementoJsonAgenda.value) {
        jsonAgenda = JSON.parse(elementoJsonAgenda.value);
        // elementoJsonAgenda.value='';
      }
    };

    /**
     * Quita los checks a los filtros seleccionados de los desplegables.
     */
    const limpiarFiltros = () => {
    // Quitar el check a los que esten marcados
      var targetsCheckboxs = capaFiltros.querySelectorAll('input[type=checkbox]');
      targetsCheckboxs.forEach(function (elemento) {
        if (elemento.checked) {
          elemento.checked = false;
        }
        if (elemento.disabled) {
          elemento.disabled = false;
        }
      });
    };

    /**
     * Dibuja como error el texto pasado por parametros.
     * @param {string} msg
     */
    const marcarError = (msg) => {
      capaError.innerHTML = msg;
    };

    /**
     * Deja vacio el texto de error.
     */
    const quitarError = () => {
      marcarError('');
    };

    /**
     * Método para ocultar un campo a través de su identificador
     * @param {String} id
     */
    const ocultar = (id) => {
      ocultarMostrarCampo(id, true);
    };

    /**
     * Método para mostrar un campo a través de su identificador
     * @param {String} id
     */
    const mostrar = (id) => {
      ocultarMostrarCampo(id, false);
    };

    /**
     * Método para mostrar u ocultar un campo a través de su identificador
     * @param {String} id
     * @param {boolean} ocultar
     */
    const ocultarMostrarCampo = (id, ocultar) => {
      const campo = document.querySelector(`#${id}`);

      if (campo) {
        if (ocultar) {
          campo.classList.add('oculto');
        } else {
          campo.classList.remove('oculto');
        }
      }
    };

    /**
     * Muestra un spinner mientras realiza la búsqueda
     * @param {string} idCapa
     */
    const mostrarSpinner = (idCapa) => {
      const html = `<div class="div__loader"><span class="calendar-spinner"></span></div>`;
      querySelector(`#${idCapa}`).innerHTML = html;
    };

    /**
     * Muestra un spinner mientras realiza la búsqueda
     * @param {string} idCapa
     */
    const quitarSpinner = (idCapa) => {
      querySelector(`#${idCapa}`).innerHTML = '';
    };

    /* function mostrarVistaListado() {
      vista = 'listado';
      eventScroll.reset();
      buscar({nuevosFiltros: false});
    } */

    /**
     * Realiza una busqueda desde la última posición devuelta.
     * @return {boolean} todoOk
     */
    function cargarMasListado() {
      // async function cargarMasListado () {
      const urlParams = `&v=listado${parametrosBusquedaAnterior +
        generarParametrosFiltros()}&p=${eventScroll.getPaginaActual()}&pr=${posicionResultadosBusqueda}`;


      console.log(urlParams);

      quitarError();
      return fetch(urlBuscarMasDatos + urlParams, {
        method: 'POST',
        credentials: 'same-origin',
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (json) {
          if (json) {
            posicionResultadosBusqueda = json.posicionResultado;
            dibujarResultadoListadoScroll(json.resultados);
            eventScroll.bloquearScroll(!config.permitirCargaScroll || (config.permitirCargaScroll && !json.hayMasDatos));

            if (config.type === tipo.COMPLETO) {
              if (!config.permitirCargaScroll && json.hayMasDatos) {
                dibujarBotonMasResultados();
              }
            }
          } else {
            eventScroll.bloquearScroll(true);
          }
          return true;
        })
        .catch(function (error) {
          marcarError(config.errorCargaFecth);
          console.log('Hubo un problema con la petición Fetch:' + error.message);
          return false;
        });
    }

    /**
     * Retorna un objeto con los filtros seleccionados
     * @return {Object} filtrosSeleccionados
     */
    const obtenerFiltrosSeleccionados = () => {
      var filtrosSeleccionados = {};

      if (capaFiltros) {
        capaFiltros.querySelectorAll('input[type="checkbox"]:checked')
          .forEach(function (elemento) {
            var tipoFiltro = elemento.id.substr(0, elemento.id.lastIndexOf('_'));
            var idFiltro = elemento.id.substr(elemento.id.lastIndexOf('_') + 1);
            if (!filtrosSeleccionados[tipoFiltro]) {
              filtrosSeleccionados[tipoFiltro] = [];
            }
            filtrosSeleccionados[tipoFiltro].push(idFiltro);
          });
      }
      return filtrosSeleccionados;
    };

    /* function quitarFiltros() {
      buscar({nuevosFiltros: false});
    } */

    /**
     * Dibuja la capa de filtros devueltos en la búsqueda.
     * - Si es una nueva búsqueda, se inicializa la clase Filtros.
     * - Si se está filtrando, sólo se modifican los filtros
     * @param {boolean} nuevosFiltros
     */
    const dibujarFiltros = (nuevosFiltros) => {
      if (jsonAgenda.filtros && jsonAgenda.filtros.length > 0) {
        if (nuevosFiltros || !_filtros) {
          _filtros = new Filtros(jsonAgenda.filtros || []);
          _filtros.init();
        } else {
          _filtros.modificar(jsonAgenda.filtros || []);
        }
      }
    };

    /**
     * Filtros pasados por parametros
     * @param {*} filtrosSelectOnload
     */
    const dibujarFiltrosSeleccionPrevia = (filtrosSelectOnload) => {
      cargarFiltros(filtrosSelectOnload);
      if (!jsonAgenda) {
        buscar({nuevosFiltros: false});
      }
    };

    /** Dibuja el botón de carga más resultados en el caso de que este el scroll desabilitado.
     *
     * @param {*} parent
     */
    const dibujarBotonMasResultados = () => {
      const parent = document.querySelector('.event-list');
      const button = document.createElement('button');
      button.classList.add('btn');
      button.classList.add('btn-mas');
      button.innerText = config.tituloVerMasResultados;
      button.setAttribute('aria-controls', 'event-list');

      button.addEventListener('click', function (e) {
        cargarMasListado();
        e.target.parentNode.removeChild(e.target);
      });

      parent.parentNode.appendChild(button);
    };

    /**
     * Actualiza los campos del formulario de busqueda y los filtros
     * con el state del history pasado por parametros.
     * @param {Object} stateObj
     */
    const actualizarFormBusquedaYFiltros = (stateObj) => {
      if (!stateObj.nuevosFiltros && stateObj.filtros && stateObj.filtros !== '') {
        cargarFiltros(stateObj.filtros);
      }

      if (stateObj.titulo !== undefined && elementoTermino) {
        elementoTermino.value = stateObj.titulo;
      }

      if (stateObj.fechaInicio && stateObj.fechaFin ) {
        fechaDesdeHasta.setStartDate(stateObj.fechaInicio);
        fechaDesdeHasta.setEndDate(stateObj.fechaFin);
      }
    };

    /**
     * Carga los filtros devueltos de la búsqueda
     * @param {*} filtros
     */
    const cargarFiltros = (filtros) => {
      var filtrosSeleccionados = capaFiltros.querySelectorAll('input[type="checkbox"]:checked');
      filtrosSeleccionados.forEach((filtroSeleccionado) => {
        filtroSeleccionado.checked = false;
      });

      if (filtros) {
        var jsonFiltrosSelectOnload = JSON.parse(filtros);
        var keysFiltrosSelectOnload = Object.keys(jsonFiltrosSelectOnload);
        keysFiltrosSelectOnload.forEach((key) => {
          jsonFiltrosSelectOnload[key].forEach((idFiltroPorTipo) => {
            var checkboxFiltro = capaFiltros.querySelector(`#${key}_${idFiltroPorTipo}`);
            if (checkboxFiltro) {
              checkboxFiltro.checked = true;
            }
          });
        });
      }
    };

    /**
     * Devuelve la url con los params de busqueda
     * @param {*} params
     * @return {string} url
     */
    const getUrlWithParams = (params) => {
      let url = location.href;
      if (params) {
        const searchParams = new URLSearchParams(window.location.search);
        const arrayParams = params.split('&');
        arrayParams.forEach((param) => {
          if (param) {
            const nameParam = param.slice(0, param.indexOf('='));
            const valueParam = param.slice(param.indexOf('=') + 1);
            searchParams.set(nameParam, valueParam);
          }
        });
        // url = url.replace(/[?#]$/,'');
        url = url.slice(0, url.indexOf('?') + 1) + searchParams.toString();
      }
      return url;
    };


    /**
     * Iniciliza la clase con los filtros disponibles
     * según los resultados de la búsqueda.
     * @param {Array} filtros
     */
    function Filtros(filtros) {
      this.filtros = filtros;
    }

    Filtros.prototype = {
      _addFiltro: function (idFiltro, newFiltro, anteriorElemento) {
        var htmlCapaFiltro = _filtros._getHTMLCapaFiltro(idFiltro, newFiltro);

        if (anteriorElemento) {
          anteriorElemento.insertAdjacentHTML('afterend', htmlCapaFiltro);
        } else {
          // hay que añadirlo como el primer elemento, despues del legend
          document.querySelector(`#toggle-filter_${config.identificador}_${idFiltro} legend`).insertAdjacentHTML('beforebegin', htmlCapaFiltro);
        }
        const capaFiltro = capaFiltros.querySelector(`#${idFiltro}_${newFiltro.id}`);
        _filtros._addEventFiltro(capaFiltro);
        return capaFiltro;
      },
      _crearUI: function () {
        var html = `<div class="visible-xs">
                      <div class="filters-header">
                      <h3 class="filters-header__title">${config.tituloMobileFiltrar}</h3>
                      <button class="filters-header__close _closeFilters">
                        <span class="btn-menu-close__text">${config.tituloInputCerrarMenuFiltros}</span>
                      </button>
                      </div>
                    </div>
                  <div class="filters">
                    <span class="hidden-xs">${config.tituloMobileFiltrar}</span>`;

        this.filtros.forEach(function (filtro) {
          html += _filtros._getHTMLContenedor(filtro);
        });

        html += `</div>
                <div class="visible-xs">
                  <div class="filters-footer">
                    <button class="filters-footer__watch _closeFilters">
                      <span>${config.tituloMobileVerResultados} <span id="numVerResultados_${config.identificador}">(${jsonAgenda.numResultados})</span></span>
                    </button>
                  </div>
                </div>`;

        _filtros._crearBotonFiltrarMobile();
        capaFiltros.innerHTML = html;
        const mostrarExposiciones = this.filtros.find((x) => x.id === 'tipo').array.some((t) => t.id === '7');
        if (mostrarExposiciones) {
          _filtros._crearBotonSegmentado();
        }
      },
      _crearBotonSegmentado: function () {
        capaViews.innerHTML = `
          <fieldset class="fieldset fieldset--segmented">
            <div class="field field--radio">
              <input class="field__radio" name="vistaEventos_${config.identificador}" id="actividades" value="actividades" type="radio">
              <label class="field__label" for="actividades">${config.botonSegmentadoActividades}</label>
            </div>
            <div class="field field--radio">
              <input class="field__radio" name="vistaEventos_${config.identificador}" id="exposiciones" value="exposiciones" type="radio">
              <label class="field__label" for="exposiciones">${config.botonSegmentadoExposiciones}</label>
            </div>
          </fieldset>
        `;
        if (config.viewExposiciones) {
          capaViews.querySelector('#exposiciones').checked = true;
        } else {
          capaViews.querySelector('#actividades').checked = true;
        }
      },
      _showBotonSegmentado: function () {
        mostrar(capaViews.id);
      },
      _hideBotonSegmentado: function () {
        console.log(' oculto la capa ' + capaViews.id);
        ocultar(capaViews.id);
      },
      _getHTMLContenedor: function (filtro) {
        return `<div id="filtro_${config.identificador}_${filtro.id}" class="filter">
                  <button id="filtro_boton_${config.identificador}_${filtro.id}" class="filter__button btn-toggle-filters" aria-controls="toggle-filter_${config.identificador}_${filtro.id}">
                    <span>${filtro.label}</span>
                  </button>
                  <fieldset id="toggle-filter_${config.identificador}_${filtro.id}" class="fieldset fieldset--checkboxset filter__list">
                    <legend class="fieldset__legend oculto">${filtro.label}</legend>
                    ${_filtros._getHTMLCapaFiltros(filtro)}
                  </fieldset>
                </div>`;
      },
      _getHTMLCapaFiltros: function (filtro) {
        var html = ``;
        var idFiltro = filtro.id;
        var listado = filtro.array;

        listado.forEach(function (elemento) {
          html +=_filtros._getHTMLCapaFiltro(idFiltro, elemento);
        });

        return html;
      },
      _getHTMLCapaFiltro: function (idFiltro, elemento) {
        var id = idFiltro + '_' + elemento.id;
        var numElementos = elemento.num || 0;
        return (`<div class="field field--checkbox">
                      <input class="field__checkbox" id="${id}" type="checkbox">
                      <label class="field__label" for="${id}">${elemento.texto} (<span data-numElementos="${numElementos}" id="${id}_numElementos">${numElementos}</span>)</label>
                    </div>`);
      },
      _crearBotonFiltrarMobile: function () {
        capaBotonFiltrar.innerHTML = `<button id="open-filtros_${config.identificador}" class="btn btn-filtrar" aria-expanded="false" aria-controls="filtrosAgenda_${config.identificador}">
                  <span>${config.tituloMobileBotonFiltrar}</span>
             </button>`;
      },
      _addEventFiltro: function (elemento) {
        elemento.addEventListener('click', function (event) {
          buscar({nuevosFiltros: false});
        });
      },
      _addEventBotonSegmentado: function (elemento) {
        elemento.addEventListener('change', function (e) {
          e.preventDefault();
          config.viewExposiciones = !config.viewExposiciones;

          resetDatosBusqueda();

          eventScroll.reset();
          eventScroll.bloquearScroll(!config.permitirCargaScroll );

          buscar({nuevosFiltros: true});
        });
      },
      _initEvents: function () {
        // Initialize toggle icon buttons
        var targetsFilters = capaFiltros.querySelectorAll('.btn-toggle-filters');
        for (var i = 0, max = targetsFilters.length; i < max; i++) {
          window.vg.buttons.addToggletipEventListeners(
            window.vg.buttons.toggleButtonFactory(targetsFilters[i])
          );
        }

        var botonMobileFiltrar = capaBotonFiltrar.querySelector('.btn-filtrar');
        botonMobileFiltrar.addEventListener('click', function () {
          capaFiltros.classList.add('_openfilters');
          capaFiltros.classList.remove('hidden-xs');
        });
        var botonesMobileCerrarFiltros = capaFiltros.querySelectorAll('._closeFilters');
        botonesMobileCerrarFiltros.forEach(function (boton) {
          boton.addEventListener('click', function () {
            capaFiltros.classList.remove('_openfilters');
            capaFiltros.classList.add('hidden-xs');
          });
        });

        var targetsCheckboxs = capaFiltros.querySelectorAll('input[type=checkbox]');
        for (i = 0, max = targetsCheckboxs.length; i < max; i++) {
          _filtros._addEventFiltro(targetsCheckboxs[i]);
        }

        var botonesSegmentados = document.querySelectorAll(`input[name="vistaEventos_${config.identificador}"]`);
        botonesSegmentados.forEach((boton) => {
          _filtros._addEventBotonSegmentado(boton);
        });
      },
      _addFiltrosSeleccionados: function () {
        var html = ``;
        var filtrosSeleccionados = capaFiltros.querySelectorAll('input[type="checkbox"]:checked');

        console.log('hola!!!');
        console.log(filtrosSeleccionados);

        if (filtrosSeleccionados && filtrosSeleccionados.length > 0) {
          _filtros._hideBotonSegmentado();
        } else {
          _filtros._showBotonSegmentado();
        }

        if (filtrosSeleccionados.length > 1) {
          html += _filtros._getHTMLBorrarFiltros();
        }
        filtrosSeleccionados.forEach(function (elemento) {
          html += _filtros._getHTMLFiltroSeleccionado(elemento);
        });

        capaFiltrosSeleccionados.innerHTML = html;
        _filtros._initEventsFiltrosSeleccionados();
      },
      _initEventsFiltrosSeleccionados: function () {
        const addEvent = (botonFiltroSeleccionado) => {
          botonFiltroSeleccionado.addEventListener('click', function () {
            const hacerBusqueda = true;

            if (botonFiltroSeleccionado.id === `clearAllFilters_${config.identificador}`) {
              limpiarFiltros();
            } else {
              querySelector(`#${botonFiltroSeleccionado.dataset.filtro}`).checked = false;

              /* if (document.querySelector(`#${botonFiltroSeleccionado.dataset.filtro}_numElementos`).dataset.numelementos === '0') {
                // no hace falta buscar de nuevo
                hacerBusqueda = false;
                _filtros.modificar(jsonAgenda.filtros || []);
              }*/
            }
            if (hacerBusqueda) {
              buscar({nuevosFiltros: false});
            }
          });
        };

        const botonesFiltrosSeleccionados = capaFiltrosSeleccionados.querySelectorAll('button:not([disabled])');
        botonesFiltrosSeleccionados.forEach(function (botonFiltroSeleccionado) {
          addEvent(botonFiltroSeleccionado);
        });
      },
      _getHTMLBorrarFiltros: function () {
        return `<button class="btn btn--nav" id="clearAllFilters_${config.identificador}">
                   <span class="no-wrap">${config.tituloInputQuitarFiltros}</span>
                 </button>`;
      },
      _getHTMLFiltroSeleccionado: function (elemento) {
        var textLbl = querySelector(`label[for="${elemento.id}"]`).innerText;

        return `<button data-filtro="${elemento.id}" class="btn btn--nav">
                   <span class="no-wrap">${textLbl}</span>
                 </button>`;
      },
      _setNumElementosFiltro: function (idFiltro, numElementos) {
        var capaSpan = capaFiltros.querySelector(`#${idFiltro}_numElementos`);
        // if(capaSpan) {
        capaSpan.dataset.numelementos = numElementos;
        // }
      },
      _actualizarNumElementosFiltro: function (idFiltro, numElementos) {
        var capaSpan = capaFiltros.querySelector(`#${idFiltro}_numElementos`);
        if (numElementos) {
          capaSpan.innerHTML = numElementos;
          capaSpan.dataset.numelementos = numElementos;
        } else {
          capaSpan.innerHTML = capaSpan.dataset.numelementos;
        }
      },
      _getCapaFiltro: function (idFiltro) {
        return capaFiltros.querySelector(`#${idFiltro}`);
      },
      _getFiltrosDisponibles: function (filtros) {
        var arrayFiltrosDisponibles = [];

        filtros.forEach((filtro) => {
          var anteriorElemento;
          filtro.array.forEach((elemento) => {
            var id = filtro.id + '_' + elemento.id;
            var capaFiltro = _filtros._getCapaFiltro(id);
            arrayFiltrosDisponibles.push(id);
            if (capaFiltro) {
              _filtros._setNumElementosFiltro(id, elemento.num);
            } else {
              // No existe, hay que crear el filtro
              capaFiltro = _filtros._addFiltro(filtro.id, elemento, anteriorElemento);
            }
            anteriorElemento = capaFiltro.parentElement;
          });
        });
        return arrayFiltrosDisponibles;
      },
      _enableFiltro: function (filtro) {
        filtro.parentElement.classList.remove('oculto');
        filtro.disabled = false;
      },
      _disableFiltro: function (filtro) {
        filtro.parentElement.classList.add('oculto');
        filtro.disabled = true;
      },
      _actualizarFiltros: function (arrayIdsFiltrosDisponibles) {
        const existeFiltro = (elemento) => !arrayIdsFiltrosDisponibles.find((idFiltroDisponible) => idFiltroDisponible == elemento.id);

        const gestionarFiltro = (elemento) => {
          if (existeFiltro(elemento)) {
            _filtros._disableFiltro(elemento);
            _filtros._actualizarNumElementosFiltro(elemento.id, '0');
          } else {
            _filtros._enableFiltro(elemento);
            _filtros._actualizarNumElementosFiltro(elemento.id);
          }
        };

        const allFiltros = capaFiltros.querySelectorAll('input[type="checkbox"]');
        if (arrayIdsFiltrosDisponibles.length > 0) {
          capaFiltros.disabled = false;
          allFiltros.forEach((elemento) => gestionarFiltro(elemento));

          /* capaFiltros.querySelectorAll('.filter').forEach(function (filtro) {
            var inputChecksNotDisabled = filtro.querySelectorAll('input[type="checkbox"]:not([disabled])');

            if (inputChecksNotDisabled.length == 1) {
              inputChecksNotDisabled[0].disabled = true;
            }
          });*/
        } else {
          capaFiltros.disabled = true;
          allFiltros.forEach((elemento) => _filtros._disableFiltro(elemento));
        }
      },
      _updateNumElementosTotal: function () {
        // mostrar num resultados
        capaFiltros.querySelector(`#numVerResultados_${config.identificador}`).innerHTML = `(${jsonAgenda.numResultados})`;
      },
      modificar: function (filtros) {
        if (filtros) {
          _filtros._actualizarFiltros(_filtros._getFiltrosDisponibles(filtros));
          _filtros._addFiltrosSeleccionados();

          // mostrar num resultados
          _filtros._updateNumElementosTotal();
        }
      },
      init: function () {
        // Primero elimino los existentes
        borrarCapaFiltros();
        if (this.filtros && this.filtros.length > 0) {
          _filtros._crearUI();
          _filtros._initEvents();
        }
      },
    };

    (function init() {
      // inicializar las urls de busqueda segun la visibilidad
      initVisibilidad();

      // inicializar datos resultados busqueda
      initJsonResultados();

      if (!jsonAgenda) {
        console.warn('No existe el json con los resultados de la busqueda');
        return;
      }

      if (config.type === tipo.COMPLETO) {
        dibujarSeccionBuscador();
      } else {
        dibujarSeccionSoloResultados();
      }

      // inicializar campos buscador
      initCamposBuscador();

      // if (config.type === 'all') {
      // inicilizar eventos
      initEventos();
      // }

      // generamos los parametros de busqueda para guardar
      // la primera busqueda realizada
      generarParametrosBusqueda();

      // dibujamos resultados
      dibujarResultados();

      if (config.type === tipo.COMPLETO) {
        // dibujamos filtros
        dibujarFiltros(true);

        if (config.filtrosSeleccionados) {
          dibujarFiltrosSeleccionPrevia(config.filtrosSeleccionados);
          dibujarFiltros(false);
        }

        window.onpopstate = function (e) {
          const stateObj = e.state;
          if (stateObj !== null) {
            if (stateObj.accion === 'buscar') {
              buscar(stateObj);
            }
          }
        };
      }

      return returnedObject;
    })();
  }

  if (!vg) {
    vg = {};
  }
  vg['agenda'] = {
    buscador: busquedaFactory,
  };
})(window.vg);
